.power-wrap-hs {
    .tit {
        font-size: 16px;
        color: #6FD7E8;
    }

    .power-main-wrap {}

    .fenche {
        display: flex;
        margin-top: 10px;
        // margin-left: 19px;
        // grid-template-columns: repeat(auto-fill);
        align-items: center;
        justify-items: center;
    }

    .fenche-li {
        flex: 1;

        &-main {
            width: 74px;
        }

        // margin-right: 76px;
        &-hd,
        &-ft {
            padding: 3px;
            color: #FFFFFF;
            text-align: center;
            margin-bottom: 3px;
        }

        &-ft {
            margin-top: 3px;
            margin-bottom: 0px;
        }

        &-bd {
            width: 100%;
            height: 102px;
            position: relative;

            .op-6,
            .op-7 {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background-image: url('~@/assets/images/img_zhuandong_bg@2x.png');
                background-size: cover;
                opacity: 0.6;

                &.active {
                    opacity: 1;
                }
            }


            .op-7 {
                &-1 {
                    background-image: url('~@/assets/images/img_stg_1@2x.png');
                }

                &-2 {
                    background-image: url('~@/assets/images/img_stg_2@2x.png');
                }

                &-3 {
                    background-image: url('~@/assets/images/img_stg_3@2x.png');
                }

            }

            .fenche-li-img {
                width: 100%;
                height: 100%;
                position: relative;
                background: url(~Image/img_stg_oppenall.png) center no-repeat;
                background-size: cover;
            }

            .fenche-li-img-gif {
                width: 100%;
                height: 46px;
                position: absolute;
                top: 11px;
                background: url(~Image/gd.gif) center no-repeat;
                background-size: cover;
            }

            .fenche-li-desc {
                width: 100%;
                position: absolute;
                bottom: 16px;
                color: #24c3ff;
                text-align: center;
            }

            .fenche-desc {
                position: absolute;
                width: 100%;
                left: 0;
                bottom: 16px;
                color: rgba(36, 195, 255, 1);
                text-align: center;
            }

            .fenche-ani {
                position: absolute;
                width: 29px;
                height: 32px;
                top: 20px;
                left: 50%;
                margin-left: -13px;
                background-image: url('~@/assets/images/ic_zhuandong@2x.png');
                background-size: cover;
            }

            &.active {
                opacity: 1;

                .fenche-ani {
                    -webkit-animation: round_animate 5s linear infinite;
                    animation: round_animate 5s linear infinite;
                }
            }

            .top-icon {
                position: absolute;
                width: 29px;
                height: 32px;
                top: 20px;
                left: 50%;
                transform: translateX(-50%);
                margin-left: 1px;
                background-image: url('~Image/ic_fadian_t@2x.png');
                background-size: cover;
            }
        }

        &:last-child {
            margin-right: 0;
        }
    }
}

@keyframes round_animate {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(-360deg);
    }
}


.fenche-info-main-wrap {
    width: 424px;
    height: 95px;
    background-image: url(~@/assets/images/ic_fadian_bg@2x.png);
    background-size: cover;
    padding-left: 25px;
    padding-right: 25px;

    dl {
        color: #ffffff;
        margin-top: 14px;
        text-align: center;
        margin-top: 23px;


        dt {
            font-size: 14px;
            color: rgba(141, 227, 248, 1);
            margin-bottom: 4px;
        }

        dd {
            font-size: 18px;

            span {
                font-family: "myfamily";

                &.unit {
                    font-family: Arial, Helvetica, sans-serif;
                    font-size: 14px;
                    margin-left: 5px;
                }
            }

            i {
                font-size: 30px;
            }

            // &::after {
            //   display: inline-block;
            //   content: "m³";
            //   font-size: 16px;
            //   margin-left: 11px;
            // }
        }
    }
}