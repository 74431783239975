.MainTargetWrap {
    width: 100%;

    .hidden {
        overflow: hidden;
    }

    .valueLabel {
        position: absolute;
        color: #fff;
        left: 50%;
        bottom: 43%;
        transform: translateX(-50%);
    }

    .MainTargetWrapMain {
        display: grid;
        grid-template-columns: repeat(4, 1fr);

        cursor: pointer;

        .targetItem {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .targetItemCircle {
                width: 180px;
                height: 180px;
                border-radius: 100%;
            }

            .text {
                color: #8DE3F8;
                text-align: center;
                font-size: 16px;
            }
        }

    }


    .targetTableWrap {
        height: 100%;
        overflow: hidden;
        cursor: pointer;

        .row {
            background-color: rgba(90, 147, 250, 0.1);

            &-2 {
                background: none;
            }
        }

        .body {
            height: 370px;
            overflow: hidden;

            .content {
                height: 100%;
                overflow: auto;
            }
        }

        .column {
            padding: 17px;
            color: #fff;
            text-align: center;
        }

        .column1 {
            flex: 1;
            text-align: center;
        }

        .column2 {
            flex: 2;
            text-align: center;
        }

        .column3 {
            flex: 1;
            text-align: center;
        }

        .column4 {
            flex: 1;
            text-align: center;
        }

        .targetTableMian {
            height: 100%;
        }
    }


}

.water-modal-main {}

.water-modal-wrapper {
    width: 100%;
    height: 100%;
    height: 960px;
    background-size: cover;
    background: url("~@/assets/images/QC/water-modal@2x.png") no-repeat center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;

    &-main {
        width: 1527px;
        height: 921px;
        background: rgba(26, 62, 132, 0.92);
        display: flex;
        flex-direction: column;

        &_tit {
            width: 100%;
            height: 70px;
            font-size: 43px;
            font-weight: 800;
            color: #FFFFFF;
            background: radial-gradient(circle at center, #255BA8, #012A56);
            text-align: center;
            letter-spacing: 3px;
        }

        &_bd {
            display: flex;
            flex: 1;
        }
    }



    .WaterYearWrapId2 {
        width: 100%;
        height: 100%;
    }
}

.main_right {
    width: 100%;
    height: 800px;
}

.targetTableWrap {
    height: 100%;
    width: 100%;
}