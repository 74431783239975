.wave-main,
.wave {
  width: 113px;
  height: 113px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
}
.wave {
  overflow: hidden;
  border: 1px solid #2BC0FB;
  box-shadow: inset 0 0 10px 3px rgba(26, 121, 250, 0.72);
}
.wave-tit {
  font-family: "SimSun" !important;
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 35px;
  color: #ffffff;
  z-index: 30;
  width: 100%;
  text-align: center;
}
.watercategory-wrap .wave-main {
  width: 113px;
  height: 113px;
  overflow: hidden;
  box-shadow: 0 0 10px 10px rgba(22, 58, 90, 0.6);
  margin: auto;
  margin-top: 28px;
}
.watercategory-wrap .wave {
  position: relative;
  width: 113px;
  height: 113px;
  border-radius: 50%;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}
.watercategory-wrap .wave::before,
.watercategory-wrap .wave::after {
  content: "";
  position: absolute;
  width: 1000px;
  height: 1000px;
  top: 70px;
  left: 50%;
  margin-left: -500px;
  background: linear-gradient(0deg, rgba(2, 45, 93, 0.22), rgba(2, 160, 218, 0.77));
  border-radius: 45%;
  animation: roll 5s linear infinite;
  z-index: 10;
}
.watercategory-wrap .wave::after {
  border-radius: 47%;
  background-color: rgba(255, 255, 255, 0.2);
  animation: roll 10s linear -5s infinite;
  z-index: 20;
}
@keyframes roll {
  form {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
