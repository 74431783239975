.qcold-power-wrap .tit {
  font-size: 16px;
  color: #6FD7E8;
}
.qcold-power-wrap .fenche-old {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: center;
  align-content: center;
  align-items: center;
  justify-items: center;
  grid-gap: 5px 0px;
  margin-top: 20px;
}
.qcold-power-wrap .fenche-li {
  width: 74px;
}
.qcold-power-wrap .fenche-li .fenche-li-tit {
  background-color: #085c61;
  padding: 5px;
  color: #FFFFFF;
  margin-bottom: 10px;
}
.qcold-power-wrap .fenche-li-old {
  margin-right: 0px !important;
}
.qcold-power-wrap .fenche-li-hd,
.qcold-power-wrap .fenche-li-ft {
  padding: 3px;
  color: #FFFFFF;
  text-align: center;
  margin-bottom: 3px;
}
.qcold-power-wrap .fenche-li-ft {
  margin-top: 3px;
  margin-bottom: 0px;
}
.qcold-power-wrap .fenche-li-bd {
  width: 100%;
  height: 102px;
  position: relative;
}
.qcold-power-wrap .fenche-li-bd .op-6,
.qcold-power-wrap .fenche-li-bd .op-7 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url('~@/assets/images/img_zhuandong_bg@2x.png');
  background-size: cover;
  opacity: 0.6;
}
.qcold-power-wrap .fenche-li-bd .op-6.active,
.qcold-power-wrap .fenche-li-bd .op-7.active {
  opacity: 1;
}
.qcold-power-wrap .fenche-li-bd .op-7-1 {
  background-image: url('~@/assets/images/img_stg_1@2x.png');
}
.qcold-power-wrap .fenche-li-bd .op-7-2 {
  background-image: url('~@/assets/images/img_stg_2@2x.png');
}
.qcold-power-wrap .fenche-li-bd .op-7-3 {
  background-image: url('~@/assets/images/img_stg_3@2x.png');
}
.qcold-power-wrap .fenche-li-bd .fenche-li-img {
  width: 100%;
  height: 100%;
  position: relative;
  background: url(~Image/img_stg_oppenall.png) center no-repeat;
  background-size: cover;
}
.qcold-power-wrap .fenche-li-bd .fenche-li-img-gif {
  width: 100%;
  height: 46px;
  position: absolute;
  top: 11px;
  background: url(~Image/gd.gif) center no-repeat;
  background-size: cover;
}
.qcold-power-wrap .fenche-li-bd .fenche-li-desc {
  width: 100%;
  position: absolute;
  bottom: 16px;
  color: #24c3ff;
  text-align: center;
}
.qcold-power-wrap .fenche-li-bd .fenche-desc {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 16px;
  color: #24c3ff;
  text-align: center;
}
.qcold-power-wrap .fenche-li-bd .fenche-desc-1 {
  bottom: 22px;
}
.qcold-power-wrap .fenche-li-bd .fenche-desc-2 {
  bottom: 8px;
}
.qcold-power-wrap .fenche-li-bd .fenche-ani {
  position: absolute;
  width: 29px;
  height: 32px;
  top: 20px;
  left: 50%;
  margin-left: -13px;
  background-image: url('~@/assets/images/ic_zhuandong@2x.png');
  background-size: cover;
}
.qcold-power-wrap .fenche-li-bd.active {
  opacity: 1;
}
.qcold-power-wrap .fenche-li-bd.active .fenche-ani {
  -webkit-animation: round_animate 5s linear infinite;
  animation: round_animate 5s linear infinite;
}
.qcold-power-wrap .fenche-li-bd .top-icon {
  position: absolute;
  width: 29px;
  height: 32px;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  margin-left: 1px;
  background-image: url('~Image/ic_fadian_t@2x.png');
  background-size: cover;
}
.qcold-power-wrap .fenche-li:last-child {
  margin-right: 0;
}
@keyframes round_animate {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(-360deg);
  }
}
.fenche-info-main-wrap {
  width: 424px;
  height: 95px;
  background-image: url(~@/assets/images/ic_fadian_bg@2x.png);
  background-size: cover;
  padding-left: 25px;
  padding-right: 25px;
}
.fenche-info-main-wrap dl {
  color: #ffffff;
  margin-top: 14px;
  text-align: center;
  margin-top: 23px;
}
.fenche-info-main-wrap dl dt {
  font-size: 14px;
  color: #8de3f8;
  margin-bottom: 4px;
}
.fenche-info-main-wrap dl dd {
  font-size: 18px;
}
.fenche-info-main-wrap dl dd span {
  font-family: "myfamily";
}
.fenche-info-main-wrap dl dd span.unit {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  margin-left: 5px;
}
.fenche-info-main-wrap dl dd i {
  font-size: 30px;
}
