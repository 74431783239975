.preloader,
.premaploader {
  background-image: url(~@/assets/images/img_bg_2.png);
  width: 0px;
  height: 0px;
  display: inline;
}
.premaploader {
  background-image: url(~@/assets/images/img_map_lishimen@2x.png);
}
.prehuangyinloader {
  background-image: url(~@/assets/images/img_huanying@2x.png);
}
