.floodWater-config-wrap .tit {
  font-size: 16px;
  color: #6FD7E8;
}
.floodWater-config-wrap .floodWater-wrap-main-l {
  width: 343px;
  height: 259px;
  background-image: url(~Image/ic_yihongdao_bg_2@2x.png);
  background-size: cover;
  flex-wrap: wrap;
  padding-top: 33px;
  overflow: hidden;
}
.floodWater-config-wrap .floodWater-wrap-main-l li {
  width: 25%;
}
.floodWater-config-wrap .floodWater-wrap-main-l li dt {
  font-size: 14px;
  color: #8DE3F8;
  text-align: center;
}
.floodWater-config-wrap .floodWater-wrap-main-l li dd {
  margin-top: 12px;
  color: #fff;
  text-align: center;
}
.floodWater-config-wrap .floodWater-wrap-main-l li dd span {
  font-size: 14px;
}
.floodWater-config-wrap .floodWater-wrap-main-l li dd span i {
  font-size: 24px;
}
.floodWater-config-wrap .floodWater-wrap-main-l li dd .unit {
  margin-left: 3px;
}
.floodWater-config-wrap .floodWater-wrap-main-r-c {
  width: 103px;
  height: 134px;
  background-image: url(~Image/ic_yihongdao_bg_3@2x.png);
  background-size: cover;
  padding-top: 33px;
  overflow: hidden;
}
.floodWater-config-wrap .floodWater-wrap-main-r-c:last-child {
  padding-top: 25px;
  margin-top: -10px;
}
.floodWater-config-wrap .floodWater-wrap-main-r-c dt {
  font-size: 14px;
  color: #14E08C;
  text-align: center;
}
.floodWater-config-wrap .floodWater-wrap-main-r-c dd {
  margin-top: 12px;
  color: #fff;
  text-align: center;
}
.floodWater-config-wrap .floodWater-wrap-main-r-c dd span {
  font-size: 14px;
}
.floodWater-config-wrap .floodWater-wrap-main-r-c dd span i {
  font-size: 24px;
}
.floodWater-config-wrap .floodWater-wrap-main-r-c dd .unit {
  margin-left: 3px;
}
