.downstream-wrap {
    position: absolute;
    right: -263px;
    bottom: 3px;
    width: 254px;
    height: 211px;
    background: rgba(66, 163, 125, 0.1);
    text-align: center;

    .tag-wrapper {
        position: absolute;
        left: 22px;
        // top: -16px;

        .tag-bg {
            position: relative;
            top: 0;
            left: 0px;
            width: 79px;
            height: 18px;
            border-radius: 0px 0px 17px 17px;
            // background-color: red;
            // background-size: 50%;
            // background-repeat: no-repeat;
            background-color: rgba(0, 0, 0, 0.1);
        }

        .tag-main {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            color: #fff;
            padding: 5px 10px;
            background-color: #42A37D;
            border-radius: 16px;
            white-space: nowrap;
            top: -14px
        }
    }

    .down-flow {
        font-size: 16px;
        color: #fff;
        margin-top: 32px;
        margin-bottom: 21px;
    }

    .down-img {
        width: 232px;
        height: 117px;
        background: url('~@/assets/images/ZJ/downstream@2x.png') no-repeat center;
        background-size: cover;
        display: inline-block;
        position: relative;
    }

    .down-wave {
        position: absolute;
        left: 18px;
        bottom: 10px;
        width: 71px;
        height: 74px;
        background-color: #6FD7E8;

        .down-wave-txt {
            position: absolute;
            color: #4B9EE3;
            font-size: 14px;
            width: 100%;
            text-align: center;
            top: -14px;
            left: 50%;
            transform: translateX(-50%);

            .arrow {
                display: inline-block;
                border-top: 5px solid #4B9EE3;
                border-bottom: 5ppx solid transparent;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                vertical-align: middle;
            }
        }
    }
}