.floodWater-wrap .tit {
  font-size: 16px;
  color: #6FD7E8;
}
.floodWater-wrap-main {
  width: 424px;
  height: 145px;
  background-image: url(~@/assets/images/ic_yihongdao_bg@2x.png);
  background-size: cover;
}
.floodWater-wrap-main ul {
  padding-left: 22px;
  padding-right: 22px;
  padding-top: 33px;
}
.floodWater-wrap-main ul li dt {
  font-size: 14px;
  color: #8DE3F8;
}
.floodWater-wrap-main ul li dd {
  margin-top: 12px;
  color: #fff;
  text-align: center;
}
.floodWater-wrap-main ul li dd span {
  font-size: 14px;
}
.floodWater-wrap-main ul li dd span i {
  font-size: 24px;
}
.floodWater-wrap-main ul li dd .unit {
  margin-left: 3px;
}
