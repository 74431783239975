.tit-r-item {
  font-size: 12px;
  color: #68A8CC;
}
.tit-r-item span {
  font-size: 14px;
}
.tit-r-item .c1 {
  color: #468FE5;
}
.tit-r-item .c2 {
  color: #6FD7E8;
}
.tit-r-item .j {
  color: #159F8E;
}
.tit-r-item .s {
  color: #EC5A26;
}
.tit-r-item .iconfont {
  font-size: 14px;
  display: inline-block;
}
.tit-r-item .iconfont.rotate {
  transform: rotate(180deg);
}
.area-wrap {
  width: 390px;
  height: 115px;
}
.weatherlevel-line-wrap {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.weatherlevel-line-wrap::after {
  content: "";
  position: absolute;
  height: 96px;
  width: 1px;
  background-color: #fff;
  top: 0;
  left: 35px;
  -webkit-transform: scaleY(0.5);
  transform: scaleY(0.5);
  margin-top: -70px;
}
