.nj-floodWater-wrap .tit {
  font-size: 16px;
  color: #6FD7E8;
}
.nj-floodWater-wrap-main {
  width: 100%;
  height: 210px;
}
.nj-floodWater-wrap-main .nj-floodWater-wrap-main-l {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-image: url(~@/assets/images/ic_yihongdao_bg_nj@2x.png);
  display: flex;
  align-items: center;
}
.nj-floodWater-wrap-main ul {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 10px;
}
.nj-floodWater-wrap-main ul li {
  text-align: center;
}
.nj-floodWater-wrap-main ul li dt {
  font-size: 14px;
  color: #8DE3F8;
}
.nj-floodWater-wrap-main ul li dd {
  margin-top: 12px;
  color: #fff;
}
.nj-floodWater-wrap-main ul li dd span {
  font-size: 14px;
}
.nj-floodWater-wrap-main ul li dd span i {
  font-size: 20px;
}
.nj-floodWater-wrap-main ul li dd .unit {
  margin-left: 3px;
}
.tx-floodWater-wrap-main-r-c {
  padding-top: 27px !important;
}
.ft-18 {
  font-size: 18px;
}
