.emergency-wrap .tit {
  font-size: 16px;
  color: #68a8cc;
}
.emergency-wrap .turntable-wrap {
  position: relative;
  width: 207px;
  height: 84px;
  background-image: url('~@/assets/images/img_yingji_bg@2x.png');
  background-size: cover;
  margin: 58px auto 20px;
}
.emergency-wrap .turntable-wrap .turntable-desc {
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  width: 131px;
  height: 47px;
  background-size: cover;
  text-align: center;
}
.emergency-wrap .turntable-wrap .turntable-desc-before {
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  /* keyframe名称 动画完成时间 速度曲线 开始前的延迟 播放次数 是否反向播放*/
}
.emergency-wrap .turntable-wrap .turntable-desc-img {
  width: 37px;
  height: 37px;
  background-size: cover;
  animation: Tada 1s 2s both infinite;
}
.emergency-wrap .turntable-wrap .turntable-desc-img.bg-1 {
  background-image: url('~@/assets/images/ic_yujing_1@2x.png');
}
.emergency-wrap .turntable-wrap .turntable-desc-img.bg-2 {
  background-image: url('~@/assets/images/ic_yujing_2@2x.png');
}
.emergency-wrap .turntable-wrap .turntable-desc-img.bg-3 {
  background-image: url('~@/assets/images/ic_yujing_3@2x.png');
}
.emergency-wrap .turntable-wrap .turntable-desc-img.bg-4 {
  background-image: url('~@/assets/images/ic_yujing_4@2x.png');
}
.emergency-wrap .turntable-wrap .turntable-desc .c-1 {
  color: #fa0869;
}
.emergency-wrap .turntable-wrap .turntable-desc .c-2 {
  color: #ff9b1a;
}
.emergency-wrap .turntable-wrap .turntable-desc .c-3 {
  color: #fef620;
}
.emergency-wrap .turntable-wrap .turntable-desc .c-4 {
  color: #20ecfe;
}
.emergency-wrap .turntable-wrap .none {
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  color: #8FDAEE;
}
.ic-skyj-1 {
  background-image: url('~@/assets/images/ic_skyj_1.png');
}
.ic-skyj-2 {
  background-image: url('~@/assets/images/ic_skyj_2.png');
}
.ic-skyj-3 {
  background-image: url('~@/assets/images/ic_skyj_3.png');
}
.ic-skyj-4 {
  background-image: url('~@/assets/images/ic_skyj_4.png');
}
@keyframes Tada {
  0% {
    transform: scale(1);
  }
  10%,
  20% {
    transform: scale(0.9) rotate(-3deg);
  }
  30%,
  50%,
  70%,
  90% {
    transform: scale(1.1) rotate(3deg);
  }
  40%,
  60%,
  80% {
    transform: scale(1.1) rotate(-3deg);
  }
  100% {
    transform: scale(1) rotate(0);
  }
}
