.qcWaterLevelWrapper {
    width: 1920px;
    padding: 20px;
    padding-bottom: 0;

    .qcPowerStation-wrapper_left {
        width: 1082px;
    }

    .qcPowerStation-wrapper_right {
        margin-left: 39px;
    }
}