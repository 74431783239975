@c1 : #67cbac;

@c2 : #ff8a3c;

@c3 : #e95a54;

.navigationWrapper {
    width: 60px;
    height: 60px;
    right: 0;
    border-radius: 50px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    position: fixed;
    bottom: 25px;
    right: 30px;
    font-size: 50px;
    color: #fff;
    justify-content: center;
    border: 2px solid #6FD7E8;
    box-shadow: 0px 0px 10px 2px #6FD7E8 inset;
    opacity: 0.5;

    &.active {
        opacity: 1;
    }

    .messangersBlock {
        display: block;
        visibility: hidden;
        opacity: 0;
        transition: ease-in-out .2s all;
        transform: translate3d(0, -20%, 0);
        width: 210px;
        bottom: 70px;
        right: 0;
        position: absolute;
        background-color: rgb(8, 27, 53, 0.4);
        border-radius: 8px;
        border: 1px solid #6FD7E8;

        &.active {
            visibility: visible;
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }

        &::before {
            position: absolute;
            bottom: -8px;
            right: 25px;
            left: auto;
            display: inline-block !important;
            border-right: 8px solid transparent;
            border-top: 8px solid #6FD7E8;
            border-left: 8px solid transparent;
            content: '';


        }

        &::after {
            position: absolute;
            bottom: -6px;
            right: 25px;
            left: auto;
            display: inline-block !important;
            border-right: 8px solid transparent;
            border-top: 8px solid rgb(8, 27, 53, 0.4);
            border-left: 8px solid transparent;
            content: '';
        }

    }

    .static {
        transition: .2s all;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 26px;
        color: #6FD7E8;
    }

    .close {
        transition: .2s all;
    }

    .arcuHide {
        transform: scale(0);
        opacity: 0;
    }

    .arcuShow {
        transform: scale(0);
        opacity: 1;
    }
}



.messangersListContainer {
    overflow: hidden;

    &.arcuDowntoup {
        transform: translate3d(0, 0, 0);
        transition: transform .4s;
        transition-timing-function: cubic-bezier(.7, 0, .3, 1);

        li {
            transition: transform .4s;
            transition-timing-function: cubic-bezier(.7, 0, .3, 1);
            transition-duration: .4s;
            transform: translate3d(0, 0, 0);
            display: flex;
            padding: 0px 32px;
            cursor: pointer;

            &:hover {
                background-color: rgba(111, 215, 232, 0.2);


                .liLabel {
                    color: #fff;
                }
            }

            .liIconWrapper {
                display: flex;
                width: 100%;
                border-bottom: 2px solid #346B85;
                padding: 13px 21px;

            }

            &:last-child {
                .liIconWrapper {
                    border-bottom: none;
                }
            }

            .liLabel {
                color: #6FD7E8;
                font-size: 16px;
                line-height: 40px;

                &.liIconDaba {}
            }



            .liIcon {
                display: block;
                width: 44px;
                height: 44px;
                margin-right: 15px;
                font-size: 12PX;
                line-height: 40PX;
                border-radius: 100%;
                border: 2px solid @c1;
                font-size: 12px;

                &.daba1 {
                    // &.c1 {
                    // box-shadow: 0px 0px 20px 5px @c1 inset;
                    background-image: url('~@/assets/images/QC/mainScreen@2x.png');
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    border: none;
                    // }
                }

                &.daba {

                    &.c1 {
                        box-shadow: 0px 0px 20px 5px @c1 inset;
                        background-image: url('~@/assets/images/QC/icon_daba_green@2x.png');
                        background-size: contain;
                    }
                }

                &.shuizhi {

                    &.c1 {
                        box-shadow: 0px 0px 20px 5px @c1 inset;
                        background-image: url('~@/assets/images/QC/icon_shuizhi_silei@2x.png');
                        background-size: contain;
                    }

                    &.c2 {
                        box-shadow: 0px 0px 20px 5px @c2 inset;
                        background-image: url('~@/assets/images/QC/icon_shuizhi_sanlei@2x.png');
                        background-size: contain;
                    }

                    &.c3 {
                        box-shadow: 0px 0px 20px 5px @c3 inset;
                        background-image: url('~@/assets/images/QC/icon_shuizhi_yilei@2x.png');
                        background-size: contain;
                    }
                }

                &.dianzhan {

                    &.c1 {
                        box-shadow: 0px 0px 20px 5px @c1 inset;
                        background-image: url('~@/assets/images/QC/icon_dianzhan_green@2x.png');
                        background-size: contain;
                    }
                }

                &.kuqu {

                    &.c1 {
                        box-shadow: 0px 0px 20px 5px @c1 inset;
                        background-image: url('~@/assets/images/QC/icon_kuqu_green@2x.png');
                        background-size: contain;
                    }
                }

                // &-shuizhi {

                //     &-1 {
                //         background-image: url('~@/assets/images/QC/icon_shuizhi_silei@2x.png');
                //         background-size: contain;
                //     }

                //     &-2 {
                //         background-image: url('~@/assets/images/QC/icon_shuizhi_sanlei@2x.png');
                //         background-size: contain;
                //     }

                //     &-3 {
                //         background-image: url('~@/assets/images/QC/icon_shuizhi_yilei@2x.png');
                //         background-size: contain;
                //     }

                // }

            }
        }
    }
}