.screen-video-wrap {
  background-color: rgba(0, 0, 0, 0.6);
}
.screen-video-wrap .ant-spin-container {
  height: 100%;
}
.screen-video-wrap .ant-spin-nested-loading {
  height: 100%;
}
.screen-video-wrap .ant-spin-nested-loading > div > .ant-spin {
  max-height: 100% !important;
}
.new-huanying-child-wrap-img {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  text-align: center;
}
.new-huanying-child-wrap-img .ant-spin-container {
  display: flex;
  align-items: center;
}
.new-huanying-child-wrap-img .new-screen-image {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.new-huanying-child-wrap-img .loading-wrap {
  width: 200px;
  height: 200px;
}
.new-huanying-child-wrap-img .slider-bg {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.new-huanying-child-wrap-img .slider-bg .slider-list-wrap {
  width: 56%;
  height: 74%;
  overflow: hidden;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.new-huanying-child-wrap-img .slider-bg .slider-list-wrap img {
  width: 100%;
}
.new-huanying-child-wrap-img .slider-bg .slider-list-tit {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 100px;
  color: #FFC000;
  letter-spacing: 22px;
  white-space: nowrap;
  word-break: break-all;
  width: auto;
  text-shadow: 5px 2px 6px rgba(0, 0, 0, 0.6);
}
