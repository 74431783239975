.com-logo {
  position: absolute;
  right: 60px;
  bottom: 15px;
  width: 309px;
  height: 56px;
  background-image: url(~@/assets/images/img_logo@2x.png);
  background-size: cover;
  margin: auto;
  z-index: 88;
}
