@import '../WaterLevel/index.module.less';

.StationMapWrap {
    width: 100%;

    .StationMapWrapMain {
        position: relative;
        width: 100%;
        height: 544px;
        background: url(~@/assets/images/QC/map.png) no-repeat center;
        background-size: contain;
    }

    .tips {
        position: absolute;

        .tis-name {
            position: absolute;
            bottom: 2px;
            left: 50%;
            transform: translateX(-50%);
            font-size: 18px;
            font-weight: 500;
            color: #FFFFFF;
            white-space: nowrap;
            padding: 5px 15px;
            background-image: linear-gradient(0deg, #67CBAC, #02865F);

            &-l {
                left: -108%;
            }

            &-r {
                left: 244%;
            }
        }

        .tips-foot {
            width: 46px;
            height: 31px;
            border: 1px solid #1CF8B7;
            border-radius: 50% 50%;
            position: relative;
            cursor: pointer;

            &-before {
                content: '';
                display: block;
                width: 46px;
                height: 31px;
                border-radius: 50% 50%;
                opacity: .7;
                background-color: #1CF8B7;
                animation: scale 2s infinite cubic-bezier(0, 0, .49, 1.02);
                animation-delay: 200ms;
            }

            /*0%开始，100%结束*/
            @keyframes scale {
                0% {
                    transform: scale(1)
                }

                50%,
                75% {
                    transform: scale(2)
                }

                78%,
                100% {
                    opacity: 0
                }
            }

            &-c {
                position: absolute;
                left: 50%;
                top: 50%;
                width: 29px;
                height: 20px;
                border: 2px solid #1CF8B7;
                border-radius: 50% 50%;
                transform: translate(-50%, -50%);

                .tips-foot-c-c {
                    position: relative;
                    left: 50%;
                    top: 50%;
                    width: 21px;
                    height: 11px;
                    background: #1CF8B7;
                    border-radius: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            &-cylinder {
                position: absolute;
                bottom: 5px;
                left: 0;
                width: 22px;
                height: 150px;
                z-index: 99;
                box-sizing: border-box;
                border: 2px solid;
                border-bottom: 0;

                &-val {
                    width: 60px;
                    position: relative;
                    top: -2px;
                    left: 28px;
                    padding: 4px 0;
                    background: linear-gradient(0deg, #313CFB, #0A14BC);
                    border: 1px solid #5A63FF;
                    border-radius: 2px;
                    font-size: 20px;
                    font-weight: 600;
                    color: #FFFFFF;
                    text-align: center;

                    &-l {
                        left: -66px;
                    }
                }
            }
        }
    }
}