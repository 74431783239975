.DamLeft-wrapper {
    background-color: rgba(175, 76, 252, 0.1);
    border-radius: 10px;
    position: relative;
    width: 824px;

    // background-image: radial-gradient(132px 44px at 152px -21px, transparent 22px 22px, rgba(175, 76, 252, 0.1));
    // background-image: radial-gradient(20px 15px at left 50% bottom 10px, red 10px, transparent 11px);
    .tag-wrapper {
        position: absolute;
        left: 22px;
        // top: -16px;

        .tag-bg {
            position: relative;
            top: 0;
            left: 0px;
            width: 131px;
            height: 22px;
            border-radius: 0px 0px 17px 17px;
            // background-color: red;
            // background-size: 50%;
            // background-repeat: no-repeat;
            background-color: rgba(0, 0, 0, 0.1);
        }

        .tag-main {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            color: #fff;
            padding: 8px 20px;
            background-color: #962EE6;
            border-radius: 16px;
            white-space: nowrap;
            top: -14px
        }
    }

    .DamLeft-main {}


    /*堤坝*/
    .dykeDam-wrap {
        height: 734px;
        padding-top: 68px;

        &-hd {
            width: 666px;
            margin: auto;
            margin-top: 46px;

            &-item {
                margin-bottom: 69px;
                display: flex;
                flex: 1;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                &-h4 {
                    width: 218px;
                    height: 60px;
                    background-image: url('~@/assets/images/img_kurongdata_bg_3@2x.png');
                    background-size: cover;

                    h4 {
                        font-size: 20px;
                        font-weight: 500;
                        color: #FFFFFF;
                        text-align: center;
                        line-height: 55px;
                    }
                }

                &-num {
                    color: #F7E14E;
                    font-size: 18px;
                    text-align: center;

                    span {
                        margin-right: 10px;
                        font-size: 40px;
                        display: inline-block;
                        font-family: "myFamily";
                    }
                }
            }
        }

        &-bd {
            width: 684px;
            height: 474px;
            position: relative;
            margin: auto;
            margin-left: 45px;

            .tips-wrap {
                position: absolute;
                font-size: 16px;
                font-weight: 500;
                color: #4B9EE3;
                font-weight: 500;
                z-index: 11;
                right: 112px;
                top: -5px;

                .tip-item {
                    padding-bottom: 5px;
                    width: 160px;
                    margin-bottom: 20px;

                    span {
                        font-size: 14px;
                    }

                    border-bottom: 2px solid #4B9EE3;

                    &.l1 {
                        margin-left: 41px;
                    }

                    &.l2 {
                        margin-left: 76px;
                    }

                    &.l3 {
                        margin-left: 109px;
                    }
                }
            }

            .dykeDam-wrap-bd-img-wrapper {
                position: absolute;
                width: 100%;
                height: 100%;
            }

            &-img {
                position: relative;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                left: 0;
                background-image: url('~@/assets/images/ZJ/img_zhougongzhai_daba@2x.png');
                background-size: cover;
                z-index: 9;


            }

            .mark-line {
                position: absolute;
                left: 0;
                font-size: 18px;
                top: 18px;
                color: #4AD6FE;
                display: inline-block;
                padding-bottom: 17px;
                border-bottom: 4px dashed #4AD7FF;
                width: 425px;
                text-align: center;
                z-index: 1;
            }

            .wave-wrap {
                position: absolute;
                bottom: 61px;
                left: 0;
                z-index: 8;
                width: 400px;
                height: 412px;
                overflow: hidden;

                .wave-svg {
                    width: 400px;
                    height: 100%;
                    position: absolute;
                }

            }

            .kedu {
                position: absolute;
                top: -1px;
                left: -35px;
                z-index: 10;
                font-size: 14px;
                width: 35px;
                background-image: url('~@/assets/images/ZJ/img_zhougongzhai_ruler@2x.png');
                background-size: cover;
                height: 465px;

                ul {
                    text-align: center;
                    margin-right: 8px;

                    li {
                        color: rgba(111, 215, 232, 1);
                        margin-bottom: 35px
                    }
                }
            }
        }
    }

    .DamLeft-footer {
        padding: 17px 20px 0px;
        margin-top: 42px;
    }
}