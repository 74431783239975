.xx-floodWater-wrap .tit {
  font-size: 16px;
  color: #6FD7E8;
}
.xx-floodWater-wrap-main-ll {
  width: 420px;
  height: 135px;
  background-image: url(~@/assets/images/XX/ic_yihongdao_bg@2x.png);
  background-size: cover;
}
.xx-floodWater-wrap-main-ll ul {
  padding-left: 42px;
  padding-right: 42px;
  padding-top: 26px;
}
.xx-floodWater-wrap-main-ll ul li dt {
  font-size: 14px;
  color: #8DE3F8;
  text-align: center;
}
.xx-floodWater-wrap-main-ll ul li dd {
  margin-top: 12px;
  color: #fff;
  text-align: center;
}
.xx-floodWater-wrap-main-ll ul li dd span {
  font-size: 14px;
}
.xx-floodWater-wrap-main-ll ul li dd span i {
  font-size: 20px;
}
.xx-floodWater-wrap-main-ll ul li dd .unit {
  margin-left: 3px;
}
.xx-floodWater-wrap-main-rr-c {
  width: 210px;
  height: 135px;
  background-image: url(~Image/XX/ic_yihongdao_bg_1@2x.png);
  background-size: cover;
  padding-top: 44px;
  overflow: hidden;
}
.xx-floodWater-wrap-main-rr-c dt {
  font-size: 14px;
  color: #14E08C;
  text-align: center;
}
.xx-floodWater-wrap-main-rr-c dd {
  margin-top: 12px;
  color: #fff;
  text-align: center;
}
.xx-floodWater-wrap-main-rr-c dd span {
  font-size: 14px;
}
.xx-floodWater-wrap-main-rr-c dd span i {
  font-size: 24px;
}
.xx-floodWater-wrap-main-rr-c dd .unit {
  margin-left: 3px;
}
