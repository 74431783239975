.water-supply-main-wrap-2 {
  position: absolute;
  top: -30px;
  width: 936px;
  height: 39px;
  background-size: cover;
  box-sizing: border-box;
  padding-left: 38px;
  margin-left: 48px;
}
.water-supply-main-wrap-2 table {
  color: #fff;
}
.water-supply-main-wrap-2 table th {
  color: #8DE3F8;
  font-size: 14px;
  padding-bottom: 10px;
}
.water-supply-main-wrap-2 table .num {
  font-family: "myfamily";
  min-width: 60px;
}
.water-supply-main-wrap-2 table td {
  text-align: center;
}
.water-supply-main-wrap-2 table td i {
  font-size: 30px;
}
.water-supply-main-wrap-2 table .tag {
  background-color: #3471A8 !important;
  font-size: 14px;
  border-radius: 4px;
  padding: 8px;
  display: block;
  width: 44px;
  margin-left: 5px;
}
.water-supply-main-wrap-2 table .w40 {
  width: 40px;
  vertical-align: bottom;
}
.water-supply-main-wrap-2 table .purple-bg {
  background-color: rgba(90, 147, 250, 0.2);
  padding: 4px 0;
}
.water-supply-main-wrap-2 table .blue-bg {
  background-color: rgba(90, 147, 250, 0.2);
  padding: 4px 0;
}
.water-supply-main-wrap-2 .closeBtn {
  position: absolute;
  right: -12px;
  top: -12px;
  font-size: 24px;
  color: #fff;
  cursor: pointer;
}
