.xx-power-wrap .fenche {
  margin-left: 0;
}
.xx-power-wrap .fenche-li-img {
  width: 100%;
  height: 100%;
  position: relative;
  background: url(~Image/img_stg_oppenall.png) center no-repeat;
  background-size: cover;
}
.xx-power-wrap .fenche-li-img-gif {
  width: 100%;
  height: 46px;
  position: absolute;
  top: 11px;
  background: url(~Image/gd.gif) center no-repeat;
  background-size: cover;
}
.xx-power-wrap .fenche-li-desc {
  width: 100%;
  position: absolute;
  bottom: 16px;
  color: #24c3ff;
  text-align: center;
}
