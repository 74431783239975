.qc-PoweInfo-wrap {
  padding: 14px;
  width: 392px;
  height: 347px;
  background-color: rgba(15, 78, 208, 0.3);
}
.qc-PoweInfo-wrap .info-list .item-info {
  width: 323px;
  height: 94px;
  background: url("~@/assets/images/QC/ic_dangqian_bg@2x.png") center;
  background-size: cover;
  color: #28d4ff;
}
.qc-PoweInfo-wrap .info-list .item-info .item-label {
  font-size: 14px;
}
.qc-PoweInfo-wrap .info-list .item-info .item-num {
  margin-left: 33px;
}
.qc-PoweInfo-wrap .info-list .item-info .item-num i {
  font-size: 38px;
  font-family: "myFamily";
}
.qc-PoweInfo-wrap .info-list .item-info .item-num .unit {
  font-size: 12px;
}
