@c1 : #67cbac;

@c2 : #ff8a3c;

@c3 : #e95a54;
@import '../WaterYear/index.module.less';

.WaterLevelWrapper {
    width: 404px;
    height: 354px;
    cursor: pointer;

    .shuizhi {
        height: 100%;
        text-align: center;
        font-size: 34px;
        font-weight: 700;
        color: #fff;
        line-height: 230px;
        background-repeat: no-repeat;
        background-position: center;

        &-1 {
            background-image: url('~@/assets/images/QC/icon_shuizhi_silei@2x.png');
            background-size: contain;
        }

        &-2 {
            background-image: url('~@/assets/images/QC/icon_shuizhi_sanlei@2x.png');
            background-size: contain;
        }

        &-3 {
            background-image: url('~@/assets/images/QC/icon_shuizhi_yilei@2x.png');
            background-size: contain;
        }

    }

    .waterLamp {
        width: 230px;
        height: 230px;
        border-radius: 100%;



        &-1 {
            animation: shadow 2s linear infinite;
            border: 2px solid @c1;
            box-shadow: 0px 0px 20px 0px @c1;

        }

        &-2 {
            animation: shadow2 2s linear infinite;
            border: 2px solid @c2;
            box-shadow: 0px 0px 20px 0px @c2;
        }

        &-3 {
            animation: shadow3 2s linear infinite;
            border: 2px solid @c3;
            box-shadow: 0px 0px 20px 0px @c3;
        }

        .waterLampMain {
            width: 100%;
            height: 100%;
            border-radius: 100%;

            &-1 {
                box-shadow: 0px 0px 20px 5px @c1 inset;
            }

            &-2 {
                box-shadow: 0px 0px 20px 5px @c2 inset;
            }

            &-3 {
                box-shadow: 0px 0px 20px 5px @c3 inset;
            }

        }



        @keyframes shadow {
            0% {
                box-shadow: 0px 0px 0px 0px @c1;
            }

            60% {

                box-shadow: 0px 0px 20px 0px @c1;
            }

            100% {

                box-shadow: 0px 0px 0px 0px @c1;
            }
        }

        @keyframes shadow2 {
            0% {
                box-shadow: 0px 0px 0px 0px @c2;
            }

            60% {

                box-shadow: 0px 0px 20px 0px @c2;
            }

            100% {

                box-shadow: 0px 0px 0px 0px @c2;
            }
        }

        @keyframes shadow3 {
            0% {
                box-shadow: 0px 0px 0px 0px @c3;
            }

            60% {

                box-shadow: 0px 0px 20px 0px @c3;
            }

            100% {

                box-shadow: 0px 0px 0px 0px @c3;
            }
        }



    }
}