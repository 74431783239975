.new-onduty-wrap .onduty-main-wrap-1 {
  width: 100%;
  overflow: hidden;
}
.new-onduty-wrap .onduty-main-wrap {
  min-height: 147px;
  color: #ffffff;
  -webkit-animation: 10s ab linear infinite normal;
  animation: 10s ab linear infinite normal;
}
.new-onduty-wrap .onduty-main-wrap table {
  text-align: center;
  font-size: 18px;
  word-break: keep-all;
  white-space: nowrap;
}
.new-onduty-wrap .onduty-main-wrap table th {
  padding-bottom: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  font-size: 16px;
}
.new-onduty-wrap .onduty-main-wrap table th span {
  display: block;
  color: #E5E5E5;
  font-size: 12px;
  padding: 5px 0;
}
.new-onduty-wrap .onduty-main-wrap table td {
  font-size: 16px;
  white-space: nowrap;
  /*控制单行显示*/
  overflow: hidden;
  /*超出隐藏*/
  text-overflow: ellipsis;
  /*隐藏的字符用省略号表示*/
}
.new-onduty-wrap .onduty-main-wrap table .td1 {
  width: 74px;
}
.new-onduty-wrap .onduty-main-wrap .tr-0 {
  background-color: rgba(6, 158, 117, 0.3);
}
.new-onduty-wrap .onduty-main-wrap .tr-0 td {
  font-weight: 500;
  color: #19F7BB;
}
.new-onduty-wrap .no-list {
  text-align: center;
}
