.union-floodWater-wrap {

    .hs-floodWater-wrap-main {
        .hs-zm {
            width: 1034px;
            height: 103px;
            background: url('~@/assets/images/ZJ/ic_yihongdao_right_bg@2x.png') no-repeat center;
            background-size: cover;

            ul {
                width: 100%;
                height: 100%;
                display: grid;
                padding: 20px;

                li {
                    text-align: center;
                    width: 20%;

                    dt {
                        color: #8de3f8;
                        margin-right: 21px;
                        font-size: 14px;
                    }

                    dd {
                        font-size: 24px;
                        margin-bottom: 14px;
                        color: #fff;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        .unit {
                            margin-left: 5px;
                            font-size: 14px;
                        }

                        .num {
                            font-family: "myfamily";
                        }
                    }
                }
            }
        }
    }
}