.weather-wrap-min .l-page-city-weather__current {
  color: #fff;
}
.weather-wrap-min .c-city-weather-current {
  border: none;
  border-radius: 16px;
  margin-top: 20px;
  background-color: #204a84;
}
.weather-wrap-min .c-city-weather-current .current-weather__bg {
  padding: 10px 20px;
  background-size: 540px auto;
  background-repeat: no-repeat;
  background-position: right top;
}
.weather-wrap-min .c-city-weather-current .current-time {
  font-size: 14px;
  line-height: 16px;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.55);
  text-align: right;
}
.weather-wrap-min .c-city-weather-current .current-live {
  white-space: nowrap;
}
.weather-wrap-min .c-city-weather-current .current-live .current-live__item {
  display: inline-block;
  vertical-align: middle;
  position: relative;
}
.weather-wrap-min .c-city-weather-current .current-live .current-live__item:first-child {
  text-align: right;
}
.weather-wrap-min .c-city-weather-current .current-live .current-live__item:last-child {
  text-align: left;
}
.weather-wrap-min .c-city-weather-current .current-live .current-live__item:last-child p {
  font-size: 52px;
  line-height: 1;
}
.weather-wrap-min .c-city-weather-current .current-live .current-live__item:last-child p:nth-of-type(2) {
  font-size: 22px;
  font-weight: 500;
}
.weather-wrap-min .c-city-weather-current .current-live .current-live__item > a {
  display: inline-block;
  max-width: 100%;
}
.weather-wrap-min .c-city-weather-current .current-live .current-live__item img {
  display: inline-block;
  width: 110px;
  height: 110px;
}
.weather-wrap-min .c-city-weather-current .current-live .current-live__item p {
  color: var(--text-black-1);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.weather-wrap-min .c-city-weather-current .current-live .current-live__item > .air-tag {
  position: absolute;
  top: -9px;
  right: 0;
}
.weather-wrap-min .c-city-weather-current .current-abstract {
  margin: 26px auto 30px;
  max-width: 514px;
  min-height: 32px;
  font-size: 15px;
  line-height: 1.5;
  text-align: center;
  color: var(--text-black-1);
}
.weather-wrap-min .c-city-weather-current .live-warning {
  padding: 13px 24px 11px 20px;
  border-radius: 12px;
  background-color: var(--city-weather-current-background-color);
  margin: 0 0 10px;
}
.weather-wrap-min .c-city-weather-current .live-warning > span {
  font-size: 15px;
  line-height: 1.5;
  color: var(--text-black-1);
}
.weather-wrap-min .c-city-weather-current .live-warning > i {
  color: var(--text-black-2);
}
.weather-wrap-min .current-basic {
  padding-top: 10px;
}
.weather-wrap-min .c-city-weather-current .current-basic {
  border-radius: 12px;
  background-color: #022452;
  display: flex;
  flex-wrap: wrap;
}
.weather-wrap-min .current-basic___item {
  flex: 0 0 50%;
  display: flex;
  margin-bottom: 5px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.weather-wrap-min .c-city-weather-current .current-basic .current-basic___item > p {
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  white-space: nowrap;
}
.weather-wrap-min .c-city-weather-current .current-basic .current-basic___item > p:last-child {
  margin-top: 5px;
  font-size: 14px;
  font-weight: normal;
  line-height: 16px;
}
.weather-wrap-min .c-city-weather-current .current-weather__bg.index {
  padding: 20px;
  background-size: 346px auto;
}
.weather-wrap-min .c-city-weather-current .current-weather__bg.index .current-weather__location > div h1,
.weather-wrap-min .c-city-weather-current .current-weather__bg.index .current-weather__location > div span {
  display: inline-block;
  vertical-align: middle;
}
.weather-wrap-min .c-city-weather-current .current-weather__bg.index .current-weather__location > div h1 {
  font-weight: normal;
  font-size: 18px;
  margin-right: 10px;
  line-height: 1;
  color: var(--text-black-1);
}
.weather-wrap-min .c-city-weather-current .current-weather__bg.index .current-weather__location > div span {
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
  color: var(--text-black-2);
}
.weather-wrap-min .c-city-weather-current .current-weather__bg.index .current-weather__location .current-time {
  font-size: 12px;
}
.weather-wrap-min .c-city-weather-current .current-weather__bg.index .current-live {
  margin-top: 32px;
}
.weather-wrap-min .c-city-weather-current .current-weather__bg.index .current-live .current-live__item:last-child p {
  font-size: 40px;
}
.weather-wrap-min .c-city-weather-current .current-weather__bg.index .current-live .current-live__item:last-child p:nth-of-type(2) {
  font-size: 16px;
}
.weather-wrap-min .c-city-weather-current .current-weather__bg.index .current-live .current-live__item img {
  width: 78px;
  height: 78px;
}
.weather-wrap-min .c-city-weather-current .current-weather__bg.index .current-live .current-live__item > .air-tag {
  top: -35px;
}
.weather-wrap-min .c-city-weather-current .current-weather__bg.index .current-abstract {
  max-width: 100%;
  min-height: 48px;
  margin: 16px auto 24px;
}
.weather-wrap-min .c-city-weather-current .current-weather__bg.index .current-abstract > a {
  display: inline-block;
  font-size: inherit;
  line-height: 1.5;
  font-weight: inherit;
  color: inherit;
  margin: 0;
}
.weather-wrap-min .c-city-weather-current .current-weather__bg.index .live-warning > span {
  font-size: 14px;
}
.weather-wrap-min .c-city-weather-current .current-weather__bg.index .current-basic {
  height: 70px;
  padding: 0 21px;
}
.weather-wrap-min .c-city-weather-current .current-weather__bg.index .current-basic .current-basic___item > p {
  font-size: 16px;
  line-height: 1;
}
.weather-wrap-min .c-city-weather-current .current-weather__bg.index .current-basic .current-basic___item > p:last-child {
  font-size: 15px;
}
