.hs-floodWater-wrap-main .hs-zm {
  width: 398px;
  height: 133.2px;
  background: url('~@/assets/images/HS/ic_yihongdao_bg@2x.png') no-repeat center;
  background-size: cover;
}
.hs-floodWater-wrap-main .hs-zm ul {
  width: 100%;
  height: 100%;
}
.hs-floodWater-wrap-main .hs-zm ul li {
  text-align: center;
}
.hs-floodWater-wrap-main .hs-zm ul li dt {
  color: #8de3f8;
  margin-bottom: 12px;
}
.hs-floodWater-wrap-main .hs-zm ul li dd {
  font-size: 24px;
  margin-bottom: 14px;
  color: #fff;
}
.hs-floodWater-wrap-main .hs-zm ul li dd:last-child {
  margin-bottom: 0;
}
.hs-floodWater-wrap-main .hs-zm ul li dd .unit {
  margin-left: 5px;
  font-size: 14px;
}
.hs-floodWater-wrap-main .hs-zm ul li dd .num {
  font-family: "myfamily";
}
.hs-floodWater-wrap-main .hs-fk .hs-fk-item {
  width: 201.1px;
  height: 133.2px;
  background: url('~@/assets/images/HS/ic_yihongdao_1_bg@2x.png') no-repeat center;
  background-size: cover;
  position: relative;
  text-align: center;
}
.hs-floodWater-wrap-main .hs-fk .hs-fk-item:last-child {
  margin-left: -2px;
  z-index: 2;
}
.hs-floodWater-wrap-main .hs-fk .hs-fk-item dt {
  font-size: 14px;
  color: #14E08C;
  text-align: center;
  margin-bottom: 17px;
}
.hs-floodWater-wrap-main .hs-fk .hs-fk-item dd {
  font-size: 24px;
  margin-bottom: 14px;
  color: #fff;
}
.hs-floodWater-wrap-main .hs-fk .hs-fk-item dd:last-child {
  margin-bottom: 0;
}
.hs-floodWater-wrap-main .hs-fk .hs-fk-item dd .unit {
  margin-left: 5px;
  font-size: 14px;
}
.hs-floodWater-wrap-main .hs-fk .hs-fk-item dd .num {
  font-family: "myfamily";
}
