.qcOnduty-wrap .onduty-main-wrap-1 {
  width: 424px;
  overflow: hidden;
}
.qcOnduty-wrap .onduty-main-wrap {
  min-height: 147px;
  color: #ffffff;
  -webkit-animation: 10s ab linear infinite normal;
  animation: 10s ab linear infinite normal;
}
.qcOnduty-wrap .onduty-main-wrap table {
  text-align: center;
  font-size: 18px;
  word-break: keep-all;
  white-space: nowrap;
}
.qcOnduty-wrap .onduty-main-wrap table th {
  padding-bottom: 17px;
  padding-left: 0px !important;
  padding-right: 0px !important;
  font-size: 16px;
}
.qcOnduty-wrap .onduty-main-wrap table td {
  width: 120px;
  padding: 10px 0;
  white-space: nowrap;
  /*控制单行显示*/
  overflow: hidden;
  /*超出隐藏*/
  text-overflow: ellipsis;
  /*隐藏的字符用省略号表示*/
}
.qcOnduty-wrap .onduty-main-wrap table .td1 {
  width: 74px;
}
.qcOnduty-wrap .onduty-main-wrap .tr-0 {
  background-color: rgba(28, 113, 227, 0.1);
}
.qcOnduty-wrap .onduty-main-wrap .tr-0 td {
  color: #4C9FE3;
}
.qcOnduty-wrap .no-list {
  text-align: center;
}
