.ant-drawer-content-wrapper {
  width: 600px !important;
}
.ant-form-item-explain.ant-form-item-explain-error {
  color: #ff4d4f;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: auto !important;
}
.pos-r {
  position: relative;
}
