.qc-DayNum-wrap {
  width: 979px;
  height: 347px;
  background-color: rgba(30, 112, 130, 0.3);
}
.qc-DayNum-wrap .DayNum-stime {
  margin-top: 30px;
  color: #28d4ff;
  font-size: 18px;
  text-align: center;
}
.qc-DayNum-wrap .DayNum-title {
  text-align: center;
  font-size: 40px;
  margin-top: 24px;
  color: #19f7bb;
  letter-spacing: 10px;
}
.qc-DayNum-wrap .DayNum-countDown {
  margin-top: 20px;
}
.qc-DayNum-wrap .DayNum-countDown .countDown-txt {
  font-size: 18px;
  color: #28d4ff;
  font-size: 24px;
}
.qc-DayNum-wrap .DayNum-countDown .countDown-num-list dd {
  margin-right: 20px;
  width: 100px;
  height: 100px;
  font-family: "myFamily";
  font-size: 90px;
  color: #19f7bb;
  background-color: #000000;
  text-align: center;
  line-height: 100px;
  border: 2px solid #243A53;
  text-shadow: 0px 0px 10px #19f7bb;
}
.qc-DayNum-wrap .DayNum-countDown .countDown-num-list dd:last-child {
  margin-right: 0;
}
.qc-DayNum-wrap .countDown-info-list dd {
  text-align: center;
  border-radius: 10px;
  padding: 11px 36px;
  background-color: #0c3568;
  color: #28d4ff;
  margin-right: 17px;
}
.qc-DayNum-wrap .countDown-info-list dd .info-num {
  font-family: "myFamily";
}
.qc-DayNum-wrap .countDown-info-list dd:last-child {
  margin-right: 0;
}
