.tableHs {
    width: 418px;
    color: #fff;
    border: 2px solid #C5C4CA;
    // border-collapse: collapse;
    table-layout: fixed;
    font-size: 16px;

    thead {
        background-color: #1D4886;

        // th {
        //     width: ;
        // }
    }


    th {
        padding: 14px 0;
        border: 1px solid #657CA8;
        vertical-align: middle;
        text-align: center;
        line-height: 20px;
    }

    .th_min {
        width: 45px;
    }

    .min {
        width: 22px !important;
        margin: auto;
    }

    .rotat {
        transform: rotate(90deg);
    }

    .w-22 {
        width: 22px !important;
    }

    td {
        border: 1px solid #657CA8;
        vertical-align: middle;
        text-align: center;
        padding: 14px 0;
        font-size: 15px;
    }

    tr {
        td {
            &:first-child {
                background-color: #1D4886;
            }
        }
    }

    .bg {
        background-color: #1D4886;
    }

    .nobg {
        background: none !important;
    }

    .h-75 {
        height: 75px;
    }
}