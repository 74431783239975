.tit-wrap {
  color: #ffffff;
  font-size: 18px;
  padding: 8px 9px 7px;
  background-image: linear-gradient(to right, rgba(29, 72, 134, 0.7), rgba(29, 72, 134, 0.1));
  border-left: 4px solid #6FD7E8;
  flex-wrap: nowrap;
}
.min-tit {
  width: 258px;
}
.large-tit {
  width: 418px;
}
.small-tit {
  width: 178px;
}
