.signalLamp-wrapper .lamp-list {
  margin-top: 20px;
  margin-bottom: 20px;
}
.signalLamp-wrapper .lamp-list ul {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-row-gap: 20px;
}
.signalLamp-wrapper .lamp-list ul .lamp-item {
  display: flex;
  align-content: center;
  justify-items: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}
.signalLamp-wrapper .lamp-list ul .lamp-item-title {
  text-align: center;
  margin-top: 10px;
  font-size: 18px;
  font-weight: 700;
}
.signalLamp-wrapper .lamp-list ul .lamp-item-title-1 {
  color: #67cbac;
}
.signalLamp-wrapper .lamp-list ul .lamp-item-title-2 {
  color: #ff8a3c;
}
.signalLamp-wrapper .lamp-list ul .lamp-item-title-3 {
  color: #e95a54;
}
.signalLamp-wrapper .lamp-list ul .lamp-item-circular {
  width: 120px;
  height: 120px;
  border-radius: 100%;
  box-shadow: 0px 0px 5px 5px #67cbac;
  animation-delay: 200ms;
}
.signalLamp-wrapper .lamp-list ul .lamp-item-circular-1 {
  animation: shadow 2s linear infinite;
  border: 2px solid #67cbac;
  box-shadow: 0px 0px 20px 0px #67cbac;
}
.signalLamp-wrapper .lamp-list ul .lamp-item-circular-2 {
  animation: shadow2 2s linear infinite;
  border: 2px solid #ff8a3c;
  box-shadow: 0px 0px 20px 0px #ff8a3c;
}
.signalLamp-wrapper .lamp-list ul .lamp-item-circular-3 {
  animation: shadow3 2s linear infinite;
  border: 2px solid #e95a54;
  box-shadow: 0px 0px 20px 0px #e95a54;
}
@keyframes shadow {
  0% {
    box-shadow: 0px 0px 0px 0px #67cbac;
  }
  60% {
    box-shadow: 0px 0px 20px 0px #67cbac;
  }
  100% {
    box-shadow: 0px 0px 0px 0px #67cbac;
  }
}
@keyframes shadow2 {
  0% {
    box-shadow: 0px 0px 0px 0px #ff8a3c;
  }
  60% {
    box-shadow: 0px 0px 20px 0px #ff8a3c;
  }
  100% {
    box-shadow: 0px 0px 0px 0px #ff8a3c;
  }
}
@keyframes shadow3 {
  0% {
    box-shadow: 0px 0px 0px 0px #e95a54;
  }
  60% {
    box-shadow: 0px 0px 20px 0px #e95a54;
  }
  100% {
    box-shadow: 0px 0px 0px 0px #e95a54;
  }
}
.signalLamp-wrapper .lamp-list ul .lamp-item-circular .lamp-item-main {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}
.signalLamp-wrapper .lamp-list ul .lamp-item-circular .lamp-item-main-1 {
  box-shadow: 0px 0px 20px 5px #67cbac inset;
}
.signalLamp-wrapper .lamp-list ul .lamp-item-circular .lamp-item-main-2 {
  box-shadow: 0px 0px 20px 5px #ff8a3c inset;
}
.signalLamp-wrapper .lamp-list ul .lamp-item-circular .lamp-item-main-3 {
  box-shadow: 0px 0px 20px 5px #e95a54 inset;
}
.signalLamp-wrapper .lamp-list ul .lamp-item-circular .lamp-item-main-daba-1 {
  background-image: url('~@/assets/images/QC/icon_daba_green@2x.png');
  background-size: contain;
}
.signalLamp-wrapper .lamp-list ul .lamp-item-circular .lamp-item-main-shuizhi-1 {
  background-image: url('~@/assets/images/QC/icon_shuizhi_silei@2x.png');
  background-size: contain;
}
.signalLamp-wrapper .lamp-list ul .lamp-item-circular .lamp-item-main-shuizhi-2 {
  background-image: url('~@/assets/images/QC/icon_shuizhi_sanlei@2x.png');
  background-size: contain;
}
.signalLamp-wrapper .lamp-list ul .lamp-item-circular .lamp-item-main-shuizhi-3 {
  background-image: url('~@/assets/images/QC/icon_shuizhi_yilei@2x.png');
  background-size: contain;
}
.signalLamp-wrapper .lamp-list ul .lamp-item-circular .lamp-item-main .lamp-item-main-shuizhi-num {
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  color: #fff;
  line-height: 120px;
}
.signalLamp-wrapper .lamp-list ul .lamp-item-circular .lamp-item-main-dianzhan-1 {
  background-image: url('~@/assets/images/QC/icon_dianzhan_green@2x.png');
  background-size: contain;
}
.signalLamp-wrapper .lamp-list ul .lamp-item-circular .lamp-item-main-kuqu-1 {
  background-image: url('~@/assets/images/QC/icon_kuqu_green@2x.png');
  background-size: contain;
}
