.power-wrap .tit {
  font-size: 16px;
  color: #6FD7E8;
}
.power-wrap .fenche {
  margin: auto;
  margin-top: 20px;
  margin-left: 19px;
}
.power-wrap .fenche-li {
  float: left;
  width: 74px;
  margin-right: 24px;
}
.power-wrap .fenche-li-hd,
.power-wrap .fenche-li-ft {
  padding: 3px;
  color: #FFFFFF;
  text-align: center;
  margin-bottom: 3px;
}
.power-wrap .fenche-li-ft {
  margin-top: 3px;
  margin-bottom: 0px;
}
.power-wrap .fenche-li-bd {
  width: 100%;
  height: 102px;
  position: relative;
}
.power-wrap .fenche-li-bd .op-6,
.power-wrap .fenche-li-bd .op-7 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url('~@/assets/images/img_zhuandong_bg@2x.png');
  background-size: cover;
  opacity: 0.6;
}
.power-wrap .fenche-li-bd .op-6.active,
.power-wrap .fenche-li-bd .op-7.active {
  opacity: 1;
}
.power-wrap .fenche-li-bd .op-7-1 {
  background-image: url('~@/assets/images/img_stg_1@2x.png');
}
.power-wrap .fenche-li-bd .op-7-2 {
  background-image: url('~@/assets/images/img_stg_2@2x.png');
}
.power-wrap .fenche-li-bd .op-7-3 {
  background-image: url('~@/assets/images/img_stg_3@2x.png');
}
.power-wrap .fenche-li-bd .fenche-desc {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 16px;
  color: #24c3ff;
  text-align: center;
}
.power-wrap .fenche-li-bd .fenche-ani {
  position: absolute;
  width: 29px;
  height: 32px;
  top: 20px;
  left: 50%;
  margin-left: -13px;
  background-image: url('~@/assets/images/ic_zhuandong@2x.png');
  background-size: cover;
}
.power-wrap .fenche-li-bd.active {
  opacity: 1;
}
.power-wrap .fenche-li-bd.active .fenche-ani {
  -webkit-animation: round_animate 5s linear infinite;
  animation: round_animate 5s linear infinite;
}
.power-wrap .fenche-li-bd .top-icon {
  position: absolute;
  width: 29px;
  height: 32px;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  margin-left: 1px;
  background-image: url('~Image/ic_fadian_t@2x.png');
  background-size: cover;
}
.power-wrap .fenche-li:last-child {
  margin-right: 0;
}
@keyframes round_animate {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(-360deg);
  }
}
.fenche-info-main-wrap {
  width: 424px;
  height: 95px;
  background-image: url(~@/assets/images/ic_fadian_bg@2x.png);
  background-size: cover;
  padding-left: 25px;
  padding-right: 25px;
}
.fenche-info-main-wrap dl {
  color: #ffffff;
  margin-top: 14px;
  text-align: center;
  margin-top: 23px;
}
.fenche-info-main-wrap dl dt {
  font-size: 14px;
  color: #8de3f8;
  margin-bottom: 4px;
}
.fenche-info-main-wrap dl dd {
  font-size: 18px;
}
.fenche-info-main-wrap dl dd span {
  font-family: "myfamily";
}
.fenche-info-main-wrap dl dd span.unit {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  margin-left: 5px;
}
.fenche-info-main-wrap dl dd i {
  font-size: 30px;
}
