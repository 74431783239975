.QcPowerStation.ant-spin-nested-loading {
  height: 100%;
}
.QcPowerStation .ant-spin-container {
  height: 100%;
}
.QcPowerStation .QcPowerStation-wrap {
  padding: 19px;
  padding-bottom: 0px;
  display: flex;
  justify-content: center;
  height: 100%;
  overflow: hidden;
}
.QcPowerStation .QcPowerStation-wrap .QcPowerStation-wrap_left .qc-left_hd {
  margin-bottom: 20px;
}
.QcPowerStation .QcPowerStation-wrap .QcPowerStation-wrap_left .qc-left_bd {
  width: 100%;
  height: 598px;
  background-color: rgba(95, 49, 152, 0.3);
}
.QcPowerStation .QcPowerStation-wrap .QcPowerStation-wrap_right {
  width: 470px;
  height: 965px;
  margin-left: 20px;
  background-color: rgba(15, 78, 208, 0.3);
  padding: 14px;
}
.QcPowerStation .QcPowerStation-wrap .QcPowerStation-wrap_right .wrap {
  margin-bottom: 20px;
}
.QcPowerStation .QcPowerStation-wrap .QcPowerStation-wrap_right .wrap:last-child {
  margin-bottom: 0;
}
