.Daba-wrap {
  height: 100%;
  width: 100%;
  padding-right: 42px;
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
  padding-bottom: 8px;
}
.Daba-wrap .Daba-main-wrap {
  position: relative;
  width: 1146px;
  height: 580px;
}
.Daba-wrap .Daba-main-wrap .Daba-Children {
  position: absolute;
  padding: 10px;
  left: -186px;
  bottom: 0;
  border: 1px dashed #3E84B4;
  width: 557px;
  height: 288px;
  z-index: 89;
}
.Daba-wrap .Daba-main-wrap .Daba-Children .tit-amount {
  text-align: center;
  color: #FFFFFF;
  margin-bottom: 10px;
}
.Daba-wrap .Daba-main-wrap .Daba-Children .tit-amount-left {
  background-color: #004b37;
  width: 170px;
  margin-right: 20px;
  padding: 5px;
  text-align: center;
}
.Daba-wrap .Daba-main-wrap .Daba-Children .tit-amount-right {
  padding: 5px;
  text-align: center;
  background-color: #004b37;
}
.Daba-wrap .Daba-main-wrap .Daba-Children .liushui-ck {
  position: relative;
  right: -545px;
  top: 139px;
  width: 0px;
  height: 30px;
  z-index: 6;
  border-left: 1px solid #002B9A;
}
.Daba-wrap .Daba-main-wrap .Daba-Children .Daba-Children-left .tit {
  text-align: center;
  background-color: #085c61;
  color: #FFFFFF;
  padding: 5px;
}
.Daba-wrap .Daba-main-wrap .Daba-Children .Daba-Children-right .tit {
  text-align: center;
  background-color: #085c61;
  color: #FFFFFF;
  padding: 5px;
}
.Daba-wrap .Daba-main-wrap .Daba-Children .fenche-li-tit {
  background-color: #085c61;
  padding: 5px;
  color: #FFFFFF;
  margin-top: 10px;
  margin-bottom: 10px;
}
.Daba-wrap .Daba-main-wrap .liushui {
  position: absolute;
  bottom: 0;
  left: 370px;
  width: 75px;
  height: 106px;
}
.Daba-wrap .Daba-main-wrap .liushui-active {
  background: url("~@/assets/images/QC/1.gif") no-repeat center;
  background-size: cover;
}
.Daba-wrap .Daba-main-wrap .liushui-2 {
  background: url("~@/assets/images/QC/2.png") no-repeat center;
  background-size: cover;
}
.Daba-wrap .Daba-main-wrap .liushui-daba {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 88;
}
.Daba-wrap .Daba-main-wrap .liushui-daba-0 {
  background: url("~@/assets/images/QC/img_bati_0@2x.png") no-repeat center;
  background-size: cover;
}
.Daba-wrap .Daba-main-wrap .liushui-daba-1 {
  background: url("~@/assets/images/QC/img_bati_1@2x.png") no-repeat center;
  background-size: cover;
}
.Daba-wrap .Daba-main-wrap .liushui-daba-2 {
  background: url("~@/assets/images/QC/img_bati_2@2x.png") no-repeat center;
  background-size: cover;
}
.Daba-wrap .Daba-main-wrap .liushui-daba-3 {
  background: url("~@/assets/images/QC/img_bati_3@2x.png") no-repeat center;
  background-size: cover;
}
.Daba-wrap .Daba-main-wrap .liushui-daba-4 {
  background: url("~@/assets/images/QC/img_bati_4@2x.png") no-repeat center;
  background-size: cover;
}
.Daba-wrap .Daba-main-wrap .liushui-daba-5 {
  background: url("~@/assets/images/QC/img_bati_5@2x.png") no-repeat center;
  background-size: cover;
}
.Daba-wrap .Daba-main-wrap .liushui-daba-6 {
  background: url("~@/assets/images/QC/img_bati_6@2x.png") no-repeat center;
  background-size: cover;
}
.Daba-wrap .Daba-main-wrap .liushui-daba-7 {
  background: url("~@/assets/images/QC/img_bati_7@2x.png") no-repeat center;
  background-size: cover;
}
.Daba-wrap .Daba-main-wrap .liushui-water-wrap {
  position: absolute;
  right: 0;
  bottom: 0px;
  width: 656px;
  height: 515px;
  z-index: 77;
  overflow: hidden;
}
.Daba-wrap .Daba-main-wrap .liushui-water-wrap .liushui-water {
  position: relative;
  right: 0;
  bottom: 551px;
  width: 656px;
  height: 134px;
  z-index: 77;
}
.Daba-wrap .Daba-main-wrap .liushui-kedu {
  width: 34px;
  height: 582px;
  position: absolute;
  right: -33px;
  bottom: 0px;
  background: url("~@/assets/images/QC/img_ruler@2x.png") no-repeat center;
  background-size: cover;
}
.Daba-wrap .Daba-main-wrap .liushui-mark-line {
  position: absolute;
  right: 0;
  font-size: 18px;
  top: 44px;
  color: #4AD6FE;
  display: inline-block;
  padding-bottom: 17px;
  border-bottom: 4px dashed #4AD7FF;
  width: 543px;
  text-align: center;
  z-index: 99;
}
.Daba-wrap .Daba-main-wrap .liushui-ck {
  position: absolute;
}
.Daba-wrap .fenche {
  margin: auto;
  display: flex;
  flex-direction: row;
  margin: 0px;
}
.Daba-wrap .fenche li {
  flex: 1;
  margin-right: 20px;
}
.Daba-wrap .fenche li:last-child {
  margin-right: 0;
}
.Daba-wrap .fenche-li {
  float: left;
  width: 74px;
  margin-right: 24px;
}
.Daba-wrap .fenche-li .fenche-li-ecol-hd {
  width: 100%;
  margin-top: 10px;
  height: 71px;
  background-color: #085c61;
  color: #fff;
}
.Daba-wrap .fenche-li-hd,
.Daba-wrap .fenche-li-ft {
  padding: 3px;
  color: #FFFFFF;
  text-align: center;
  margin-bottom: 3px;
}
.Daba-wrap .fenche-li-ft {
  margin-top: 3px;
  margin-bottom: 0px;
}
.Daba-wrap .fenche-li-bd {
  width: 100%;
  height: 102px;
  position: relative;
}
.Daba-wrap .fenche-li-bd .op-6,
.Daba-wrap .fenche-li-bd .op-7 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url('~@/assets/images/img_zhuandong_bg@2x.png');
  background-size: cover;
  opacity: 0.6;
}
.Daba-wrap .fenche-li-bd .op-6.active,
.Daba-wrap .fenche-li-bd .op-7.active {
  opacity: 1;
}
.Daba-wrap .fenche-li-bd .op-7-1 {
  background-image: url('~@/assets/images/img_stg_1@2x.png');
}
.Daba-wrap .fenche-li-bd .op-7-2 {
  background-image: url('~@/assets/images/img_stg_2@2x.png');
}
.Daba-wrap .fenche-li-bd .op-7-3 {
  background-image: url('~@/assets/images/img_stg_3@2x.png');
}
.Daba-wrap .fenche-li-bd .fenche-li-img {
  width: 100%;
  height: 100%;
  position: relative;
  background: url(~Image/img_stg_oppenall.png) center no-repeat;
  background-size: cover;
}
.Daba-wrap .fenche-li-bd .fenche-li-img-gif {
  width: 100%;
  height: 46px;
  position: absolute;
  top: 11px;
  background: url(~Image/gd.gif) center no-repeat;
  transform: rotateZ(180deg);
  background-size: cover;
}
.Daba-wrap .fenche-li-bd .fenche-li-desc {
  width: 100%;
  position: absolute;
  bottom: 16px;
  color: #24c3ff;
  text-align: center;
}
.Daba-wrap .fenche-li-bd .fenche-desc {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 16px;
  color: #24c3ff;
  text-align: center;
}
.Daba-wrap .fenche-li-bd .fenche-desc-1 {
  bottom: 22px;
}
.Daba-wrap .fenche-li-bd .fenche-desc-2 {
  bottom: 8px;
}
.Daba-wrap .fenche-li-bd .fenche-ani {
  position: absolute;
  width: 29px;
  height: 32px;
  top: 20px;
  left: 50%;
  margin-left: -13px;
  background-image: url('~@/assets/images/ic_zhuandong@2x.png');
  background-size: cover;
}
.Daba-wrap .fenche-li-bd.active {
  opacity: 1;
}
.Daba-wrap .fenche-li-bd.active .fenche-ani {
  -webkit-animation: round_animate 5s linear infinite;
  animation: round_animate 5s linear infinite;
}
.Daba-wrap .fenche-li-bd .top-icon {
  position: absolute;
  width: 29px;
  height: 32px;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  margin-left: 1px;
  background-image: url('~Image/ic_fadian_t@2x.png');
  background-size: cover;
}
.Daba-wrap .fenche-li:last-child {
  margin-right: 0;
}
.Daba-wrap .txt-c {
  text-align: center;
}
