.StationHs-wrap table {
  width: 100%;
  color: #fff;
  border: 2px solid #C5C4CA;
}
.StationHs-wrap table tr th {
  text-align: center;
  padding: 14px 0;
  border: 1px solid #657CA8;
}
.StationHs-wrap table tr th:first-child {
  font-weight: bold;
}
.StationHs-wrap table tr td {
  padding: 12px 0;
  text-align: center;
  border: 1px solid #657CA8;
}
.StationHs-wrap thead {
  background-color: #1D4886;
}
