.emergency-wrap-qc .turntable-wrap .turntable-desc-img.bg-1,
.emergency-wrap-qc .turntable-wrap .turntable-desc-img.bg-2 {
  background-image: url('~@/assets/images/ic_yujing_1@2x.png') !important;
}
.emergency-wrap-qc .turntable-wrap .turntable-desc-img.bg-3,
.emergency-wrap-qc .turntable-wrap .turntable-desc-img.bg-4 {
  background-image: url('~@/assets/images/ic_yujing_2@2x.png') !important;
}
.emergency-wrap-qc .ic-skyj-1,
.emergency-wrap-qc .ic-skyj-2 {
  background-image: url('~@/assets/images/ic_skyj_1.png') !important;
}
.emergency-wrap-qc .ic-skyj-3,
.emergency-wrap-qc .ic-skyj-4 {
  background-image: url('~@/assets/images/ic_skyj_2.png') !important;
}
.emergency-wrap-qc .c-1,
.emergency-wrap-qc .c-2 {
  color: #fa0869 !important;
}
.emergency-wrap-qc .c-3,
.emergency-wrap-qc .c-4 {
  color: #ff9b1a !important;
}
