.watercategoryMin-wrap {
  background: url(~Image/ic_line@2x.png) center no-repeat;
  width: 96px;
  height: 96px;
  background-size: cover;
  margin-left: -5px;
}
.watercategoryMin-wrap .wave-main {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.watercategoryMin-wrap .wave-main,
.watercategoryMin-wrap .wave {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
}
.watercategoryMin-wrap .wave {
  overflow: hidden;
  border: 1px solid #2BC0FB;
  box-shadow: inset 0 0 10px 3px rgba(26, 121, 250, 0.72);
}
.watercategoryMin-wrap .wave-tit {
  font-family: "SimSun" !important;
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  color: #ffffff;
  z-index: 30;
  width: 100%;
  text-align: center;
}
.watercategoryMin-wrap .wave-tit .wave-tit-class {
  font-size: 18px;
}
.watercategoryMin-wrap .wave-tit .wave-tit-desc {
  font-size: 12px;
}
.watercategoryMin-wrap .wave-main {
  width: 60px;
  height: 60px;
  overflow: hidden;
  box-shadow: 0 0 10px 10px rgba(22, 58, 90, 0.6);
  margin: auto;
}
.watercategoryMin-wrap .wave {
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}
.watercategoryMin-wrap .wave::before,
.watercategoryMin-wrap .wave::after {
  content: "";
  position: absolute;
  width: 120px;
  height: 120px;
  bottom: 0;
  left: 50%;
  background: linear-gradient(0deg, rgba(2, 45, 93, 0.22), rgba(2, 160, 218, 0.77));
  border-radius: 45%;
  transform: translate(-50%, 70%) rotate(0);
  animation: rotate 6s linear infinite;
  z-index: 10;
}
.watercategoryMin-wrap .wave::after {
  border-radius: 47%;
  background-color: rgba(255, 255, 255, 0.2);
  transform: translate(-48%, 70%) rotate(0);
  animation: rotate2 10s linear -5s infinite;
  z-index: 20;
}
@keyframes rotate {
  50% {
    transform: translate(-50%, 73%) rotate(180deg);
  }
  100% {
    transform: translate(-50%, 70%) rotate(360deg);
  }
}
@keyframes rotate2 {
  50% {
    transform: translate(-48%, 73%) rotate(180deg);
  }
  100% {
    transform: translate(-48%, 70%) rotate(360deg);
  }
}
