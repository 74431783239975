.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.wifi {
  position: absolute;
  top: 12px;
  left: 13px;
  width: 18px;
  height: 18px;
  z-index: 88;
  background: url() no-repeat center;
  background-size: cover;
}

.has-wifi {
  background-image: url(~@/assets/images/wifi.png)
}

.no-wifi {
  background-image: url(~@/assets/images/nowifi.png);
}

.allWrap{
  position:fixed;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: #001836;
}

.allWrap-child{
  position: relative;
  top:50%;
  left: 50%;
  width: 200px;
  height: 200px;
  transform: translate(-50%,-50%);
}