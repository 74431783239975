.xx-floodWater-wrap .tit {
  font-size: 16px;
  color: #6FD7E8;
}
.xx-floodWater-wrap-main-l {
  width: 294px;
  height: 135px;
  background-image: url(~@/assets/images/ic_yihongdao_bg_8@2x.png);
  background-size: cover;
}
.xx-floodWater-wrap-main-l ul {
  padding-left: 22px;
  padding-right: 22px;
  padding-top: 26px;
}
.xx-floodWater-wrap-main-l ul li dt {
  font-size: 14px;
  color: #8DE3F8;
}
.xx-floodWater-wrap-main-l ul li dd {
  margin-top: 12px;
  color: #fff;
  text-align: center;
}
.xx-floodWater-wrap-main-l ul li dd span {
  font-size: 14px;
}
.xx-floodWater-wrap-main-l ul li dd span i {
  font-size: 24px;
}
.xx-floodWater-wrap-main-l ul li dd .unit {
  margin-left: 3px;
}
.xx-floodWater-wrap-main-r-c {
  width: 141px;
  height: 135px;
  background-image: url(~Image/ic_yihongdao_bg_9@2x.png);
  background-size: cover;
  padding-top: 44px;
  overflow: hidden;
}
.xx-floodWater-wrap-main-r-c dt {
  font-size: 14px;
  color: #14E08C;
  text-align: center;
}
.xx-floodWater-wrap-main-r-c dd {
  margin-top: 12px;
  color: #fff;
  text-align: center;
}
.xx-floodWater-wrap-main-r-c dd span {
  font-size: 14px;
}
.xx-floodWater-wrap-main-r-c dd span i {
  font-size: 24px;
}
.xx-floodWater-wrap-main-r-c dd .unit {
  margin-left: 3px;
}
