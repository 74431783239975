.water-modal-wrapper {
    width: 100%;
    height: 100%;
    height: 960px;
    background-size: cover;
    background: url("~@/assets/images/QC/water-modal@2x.png") no-repeat center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;

    &-main {
        width: 1527px;
        height: 921px;
        background: #032249;
        display: flex;
        flex-direction: column;
        border: 1px solid #255BA8;

        &_tit {
            width: 100%;
            height: 70px;
            font-size: 43px;
            font-weight: 800;
            color: #FFFFFF;
            background: radial-gradient(circle at center, #255BA8, #012A56);
            text-align: center;
            letter-spacing: 3px;
        }

        &_bd {
            display: flex;
            flex: 1;

            .main_left {
                display: flex;
                align-items: center;
            }

            .main_right {
                flex: 1;
                display: flex;
                align-content: center;
                justify-content: center;
            }
        }
    }
}


@c1 : #67cbac;

@c2 : #ff8a3c;

@c3 : #e95a54;

.WaterLevelWrapper {
    width: 404px;
    height: 354px;

    .shuizhi {
        height: 100%;
        text-align: center;
        font-size: 34px;
        font-weight: 700;
        color: #fff;
        line-height: 230px;
        background-repeat: no-repeat;
        background-position: center;

        &-1 {
            background-image: url('~@/assets/images/QC/icon_shuizhi_silei@2x.png');
            background-size: contain;
        }

        &-2 {
            background-image: url('~@/assets/images/QC/icon_shuizhi_sanlei@2x.png');
            background-size: contain;
        }

        &-3 {
            background-image: url('~@/assets/images/QC/icon_shuizhi_yilei@2x.png');
            background-size: contain;
        }

    }

    .waterLamp {
        width: 230px;
        height: 230px;
        border-radius: 100%;



        &-1 {
            animation: shadow 2s linear infinite;
            border: 2px solid @c1;
            box-shadow: 0px 0px 20px 0px @c1;

        }

        &-2 {
            animation: shadow2 2s linear infinite;
            border: 2px solid @c2;
            box-shadow: 0px 0px 20px 0px @c2;
        }

        &-3 {
            animation: shadow3 2s linear infinite;
            border: 2px solid @c3;
            box-shadow: 0px 0px 20px 0px @c3;
        }

        .waterLampMain {
            width: 100%;
            height: 100%;
            border-radius: 100%;

            &-1 {
                box-shadow: 0px 0px 20px 5px @c1 inset;
            }

            &-2 {
                box-shadow: 0px 0px 20px 5px @c2 inset;
            }

            &-3 {
                box-shadow: 0px 0px 20px 5px @c3 inset;
            }

        }



        @keyframes shadow {
            0% {
                box-shadow: 0px 0px 0px 0px @c1;
            }

            60% {

                box-shadow: 0px 0px 20px 0px @c1;
            }

            100% {

                box-shadow: 0px 0px 0px 0px @c1;
            }
        }

        @keyframes shadow2 {
            0% {
                box-shadow: 0px 0px 0px 0px @c2;
            }

            60% {

                box-shadow: 0px 0px 20px 0px @c2;
            }

            100% {

                box-shadow: 0px 0px 0px 0px @c2;
            }
        }

        @keyframes shadow3 {
            0% {
                box-shadow: 0px 0px 0px 0px @c3;
            }

            60% {

                box-shadow: 0px 0px 20px 0px @c3;
            }

            100% {

                box-shadow: 0px 0px 0px 0px @c3;
            }
        }



    }
}


.targetTableWrap {
    width: 752px;
    height: 100%;
    overflow: hidden;

    .row {
        background-color: rgba(90, 147, 250, 0.1);

        &-2 {
            background: none;
        }
    }

    .body {
        height: 700px;
        overflow: hidden;

        .content {
            height: 100%;
            overflow: auto;
        }
    }

    .column {
        padding: 17px;
        color: #fff;
        text-align: center;
    }

    .column1 {
        flex: 1;
        text-align: center;
    }

    .column2 {
        flex: 2;
        text-align: center;
    }

    .column3 {
        flex: 1;
        text-align: center;
    }

    .column4 {
        flex: 1;
        text-align: center;
    }

    // .targetTableMian {
    //     height: 100%;
    // }
}