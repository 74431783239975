.bzj-floodWater-wrap .tit {
  font-size: 16px;
  color: #6FD7E8;
}
.bzj-floodWater-wrap-main {
  width: 100%;
  height: 145px;
}
.bzj-floodWater-wrap-main .bzj-floodWater-wrap-main-l {
  width: 100%;
  height: 100%;
  background: url(~@/assets/images/ic_yihongdao_tx_bg@2x.png) no-repeat center;
  background-size: contain;
}
.bzj-floodWater-wrap-main ul {
  padding-left: 22px;
  padding-right: 22px;
  padding-top: 26px;
  display: flex;
}
.bzj-floodWater-wrap-main ul li {
  flex: 1;
}
.bzj-floodWater-wrap-main ul li dt {
  text-align: center;
  font-size: 14px;
  color: #8DE3F8;
}
.bzj-floodWater-wrap-main ul li dd {
  margin-top: 12px;
  color: #fff;
  text-align: center;
}
.bzj-floodWater-wrap-main ul li dd span {
  font-size: 14px;
}
.bzj-floodWater-wrap-main ul li dd span i {
  font-size: 20px;
}
.bzj-floodWater-wrap-main ul li dd .unit {
  margin-left: 3px;
}
.bzj-floodWater-wrap-main-r-c {
  padding-top: 27px !important;
}
.bzj-18 {
  font-size: 18px;
}
