.water-supply-main-min-wrap {
  padding-left: 0;
  padding-right: 3px;
  background: none;
}
.water-supply-main-min-dl-wrap {
  width: 839px;
  background: url(~Image/ic_line_1@2x.png) no-repeat center;
  background-size: cover;
}
.water-supply-main-min-dl-wrap > div {
  padding-left: 10px;
  padding-right: 20px;
  height: 100%;
}
.water-supply-main-wrap {
  width: 936px;
  height: 95px;
  background-image: url(~@/assets/images/water-supply-hs.png);
  background-size: cover;
  box-sizing: border-box;
}
.water-supply-main-wrap.water-supply-main-wrap__gh {
  background: none;
}
.water-supply-main-wrap .water-left-wrap {
  width: 96px;
  height: 96px;
  background: url(~@/assets/images/water-left-wrap@2x.png) no-repeat center;
  background-size: cover;
}
.water-supply-main-wrap .water-right-wrap {
  width: 839px;
  height: 96px;
  background: url(~@/assets/images/water-right-wrap@2x.png) no-repeat center;
  background-size: cover;
}
.water-supply-main-wrap table {
  color: #fff;
}
.water-supply-main-wrap table th {
  color: #8DE3F8;
  font-size: 14px;
  padding-bottom: 10px;
}
.water-supply-main-wrap table .num {
  font-family: "myfamily";
  min-width: 60px;
}
.water-supply-main-wrap table td {
  text-align: center;
}
.water-supply-main-wrap table td i {
  font-size: 30px;
}
.water-supply-main-wrap table .tag {
  background-color: #C57BFE;
  font-size: 14px;
  border-radius: 4px;
  padding: 8px;
  display: block;
  width: 44px;
}
.water-supply-main-wrap table .w40 {
  width: 40px;
  vertical-align: bottom;
}
.water-supply-main-wrap table .purple-bg {
  background-color: rgba(175, 76, 252, 0.2);
  padding: 4px 0;
}
.water-supply-main-wrap table .blue-bg {
  background-color: rgba(90, 147, 250, 0.2);
  padding: 4px 0;
}
.water-supply-main-wrap table .blue-bg .tag {
  background: #7EACFF;
}
.wave-main-ls,
.wave-ls {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
}
.wave-ls {
  overflow: hidden;
  border: 1px solid #2BC0FB;
  box-shadow: inset 0 0 10px 3px rgba(26, 121, 250, 0.72);
}
.wave-tit-ls {
  font-family: "SimSun" !important;
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  color: #ffffff;
  z-index: 30;
  width: 100%;
  text-align: center;
}
.watercategory-main-wrap-ls .wave-main-ls {
  width: 60px;
  height: 60px;
  overflow: hidden;
  box-shadow: 0 0 10px 10px rgba(22, 58, 90, 0.6);
  margin: auto;
  margin-top: 18px;
}
.watercategory-main-wrap-ls .wave-ls {
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}
.watercategory-main-wrap-ls .wave-ls::before,
.watercategory-main-wrap-ls .wave-ls::after {
  content: "";
  position: absolute;
  width: 240px;
  height: 240px;
  top: 30px;
  left: 50%;
  margin-left: -120px;
  background: linear-gradient(0deg, rgba(2, 45, 93, 0.22), rgba(2, 160, 218, 0.77));
  border-radius: 45%;
  animation: roll 5s linear infinite;
  z-index: 10;
}
.watercategory-main-wrap-ls .wave-ls::after {
  border-radius: 47%;
  background-color: rgba(255, 255, 255, 0.2);
  animation: roll 10s linear -5s infinite;
  z-index: 20;
}
@keyframes roll {
  form {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
