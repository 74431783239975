.raininfo-ul {
  width: 374px;
}
.raininfo-li {
  display: flex;
  float: left;
  margin-bottom: 20px;
  width: 162px;
}
.raininfo-li-image {
  width: 56px;
  height: 56px;
  background-size: cover;
}
.raininfo-li-image-1 {
  background-image: url(../../raininfo/~Image/ic_jinriyuliang@2x.png);
}
.raininfo-li-image-2 {
  background-image: url(../../raininfo/~Image/ic_shuikushuiwei@2x.png);
}
.raininfo-li-image-3 {
  background-image: url(../../raininfo/~Image/ic_shuikukurong@2x.png);
}
.raininfo-li-image-4 {
  background-image: url(../../raininfo/~Image/ic_kurongbili@2x.png);
}
.raininfo-li-desc dt {
  margin-top: 3px;
  font-size: 14px;
  color: #878A91;
  margin-bottom: 9px;
}
.raininfo-li-desc dd {
  font-size: 12px;
  color: #FFFFFF;
  margin-bottom: 0 !important;
}
.raininfo-li-desc dd span {
  font-size: 32px;
  font-family: "myFamily";
  margin-right: 8px;
}
.m-b-0 {
  margin-bottom: 0;
}
