.PowerAmount-wrap .PowerAmount-hd {
  display: flex;
  justify-content: center;
}
.PowerAmount-wrap .PowerAmount-hd .PowerAmount-hd_item {
  width: 100%;
  height: 72px;
  border-radius: 10px;
  padding: 13px;
  color: #bc93ff;
}
.PowerAmount-wrap .PowerAmount-hd .PowerAmount-hd_item-logo {
  width: 36px;
  height: 36px;
}
.PowerAmount-wrap .PowerAmount-hd .PowerAmount-hd_item-logo.left {
  background: url("~@/assets/images/QC/ic_lejifadian@2x.png") center no-repeat;
  background-size: cover;
}
.PowerAmount-wrap .PowerAmount-hd .PowerAmount-hd_item-logo.right {
  background: url("~@/assets/images/QC/ic_nianfadian@2x.png") center no-repeat;
  background-size: cover;
}
.PowerAmount-wrap .PowerAmount-hd .PowerAmount-hd_item .item-num i {
  font-family: "myFamily";
}
.PowerAmount-wrap .PowerAmount-hd .PowerAmount-hd_left {
  color: #0aba8a;
  background-color: #004b37;
}
.PowerAmount-wrap .PowerAmount-hd .PowerAmount-hd_right {
  margin-top: 20px;
  color: #0aba8a;
  background-color: #004b37;
}
.PowerAmount-wrap .PowerAmount-bd .PowerAmount-bd-chart {
  width: 100%;
  height: 220px;
}
.PowerAmount-wrap .c68 {
  color: #67C7E8;
}
