.time-wrap {
  position: absolute;
  top: 4px;
  right: 20px;
  color: #59B9F5;
}
.time-wrap-l {
  font-size: 36px;
  font-weight: 500;
}
.time-wrap-r {
  font-size: 14px;
}
.menu {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.login-wrap {
  width: 200px;
  margin: auto;
}
.b-t {
  margin-left: 89px;
  margin-top: 10px;
}
.linshi-tit {
  width: 260px;
}
