.outwrap-wrap {
    position: absolute;

    &.zgzsk {
        right: -37px;
        bottom: 13px;
    }

    &.jksk {
        top: 161px;
        right: 55px;
    }

    &-hd {
        width: 149.8px;
        height: 38.3px;
        background: url('~@/assets/images/ZJ/outflow@2x.png') no-repeat center;
        font-size: 18px;
        text-align: center;
        line-height: 40.3px;
        margin-bottom: 23px;
        color: #fff;
        background-size: cover;
    }

    &-bd {
        font-size: 30px;
        color: #fff;
        text-align: center;

        .unit {
            margin-left: 5px;
            font-size: 24px;
        }
    }

    &-foot {
        margin-top: 10px;
        width: 149.8px;
        height: 2px;
        background: url('~@/assets/images/ZJ/outflow@2x.png') no-repeat center;
        background-size: cover;
    }
}